.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
    font-family: 'Arial, sans-serif';
}

.login-container form {
    background: white;
    padding: 2.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-container form:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.login-container h2 {
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
    font-size: 1.8em;
}

.login-container input {
    margin-bottom: 1.2rem;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    transition: border-color 0.3s ease;
}

.login-container input:focus {
    outline: none;
    border-color: #4b536a;
    box-shadow: 0 0 0 2px rgba(75, 83, 106, 0.2);
}

.login-container button {
    padding: 0.8rem;
    background-color: #4b536a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-container button:hover {
    background-color: #1d5876;
    transform: translateY(-3px);
}

.login-container p {
    text-align: center;
    color: #4b536a;
    cursor: pointer;
    margin-top: 1.2rem;
    transition: color 0.3s ease;
}

.login-container p:hover {
    color: #1d5876;
    text-decoration: underline;
}

.error-message-bar {
    background-color: #f8d7da;
    color: #721c24;
    padding: 12px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}