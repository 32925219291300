/* Add these styles to your PracticeAndTest.css file */

.esat-results-section {
  margin: 40px 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.results-description {
  margin-bottom: 30px;
  line-height: 1.6;
  color: #555;
}

.tabs-container {
  margin: 30px 0;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.tab-button {
  padding: 12px 20px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 16px;
  font-weight: 500;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button:hover {
  color: #8e44ad;
}

.tab-button.active {
  color: #8e44ad;
  border-bottom: 3px solid #8e44ad;
}

.chart-container {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.chart-container h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.score-insight {
  margin-top: 40px;
  padding: 30px;
  background-color: #f4f0f7;
  border-radius: 8px;
}

.score-insight h3 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.score-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.score-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  width: 150px;
  text-align: center;
  transition: transform 0.3s ease;
}

.score-box:hover {
  transform: translateY(-5px);
}

.score-value {
  font-size: 28px;
  font-weight: bold;
  color: #8e44ad;
  margin-bottom: 10px;
}

.score-label {
  font-size: 14px;
  color: #666;
}

.esat-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.esat-info p {
  color: #777;
}

.esat-info a {
  color: #8e44ad;
  text-decoration: none;
}

.esat-info a:hover {
  text-decoration: underline;
}

.esat-logo {
  max-width: 240px;
}

@media (max-width: 768px) {
  .tabs {
    justify-content: center;
  }
  
  .tab-button {
    padding: 10px 15px;
    font-size: 14px;
  }
  
  .score-boxes {
    flex-direction: column;
    align-items: center;
  }
  
  .score-box {
    width: 100%;
    max-width: 200px;
  }
  
  .esat-info {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}