.homepage-container {
    font-family: 'Arial, sans-serif';
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 20px 20px;
}

/* Hero Section */
.hero-section {
    text-align: center;
    background-color: white;
    padding: 50px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hero-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hero-section h2 {
    font-size: 2.2em;
    margin-bottom: 20px;
    color: #4b536a;
}

.hero-section p {
    font-size: 1.1em;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto 30px;
    color: #555;
}

#exam-choices {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
}

.exam-button {
    display: inline-block;
    padding: 15px 40px;
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.esat-button {
    background-color: #4b536a;
    color: white;
}

.esat-button:hover {
    background-color: #1d5876;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.tmua-button {
    background-color: #4a6b4b;
    color: white;
}

.tmua-button:hover {
    background-color: #3d584e;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.pat-button {
    background-color: #6b4b5a;
    color: white;
}

.pat-button:hover {
    background-color: #583d4a;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Unified Section */
.unified-section {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.content-section, .testimonials-section {
    flex: 1;
    min-width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-section:hover, .testimonials-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.content-section h3, .testimonials-section h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #4b536a;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.offer-list {
    list-style: none;
    padding: 0;
}

.offer-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
}

.offer-list li:hover {
    background-color: #f5f7fa;
}

.offer-list .icon {
    color: #4b536a;
    margin-right: 15px;
    font-size: 1.1em;
    flex-shrink: 0;
    margin-top: 3px;
}

.testimonials-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.testimonial {
    background-color: #f5f7fa;
    padding: 20px;
    border-radius: 8px;
    border-left: 4px solid #4b536a;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial:hover {
    transform: translateX(5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial p {
    margin: 0;
    line-height: 1.6;
    font-style: italic;
    color: #555;
}

/* Footer */
.footer {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.icp-number {
    font-size: 0.9em;
    color: #777;
}

.icp-number a {
    color: #4b536a;
    text-decoration: none;
    transition: color 0.3s ease;
}

.icp-number a:hover {
    color: #1d5876;
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .unified-section {
        flex-direction: column;
    }
    
    .hero-section h2 {
        font-size: 1.8em;
    }
    
    #exam-choices {
        flex-direction: column;
        gap: 15px;
    }
    
    .exam-button {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
    }
}

/* Mock Interview Preview Section */
.mock-interview-preview {
    width: 100%;
    margin: 40px 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mock-interview-preview:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mock-interview-preview h3 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #f0f0f0;
    color: #4b536a;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.mock-interview-link {
    text-align: center;
    margin-top: 25px;
    padding-top: 15px;
    border-top: 2px solid #f0f0f0;
}

.mock-interview-link .exam-button {
    display: inline-block;
    padding: 12px 35px;
    font-size: 1.1em;
}

@media (max-width: 768px) {
    .mock-interview-preview {
        margin: 30px 0;
    }
}