/* MockInterview.css */

.mi-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.mi-hero-section {
    text-align: center;
    padding: 60px;
    background-color: #f5f5f5;
    border-radius: 8px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('');
    background-size: cover;
    background-position: center;
    /* Reduce bottom padding to decrease space after subtitle */
    padding-bottom: 10px;
}

.mi-hero-section h1 {
    font-size: 36px;
    color: #1a1a1a;
    margin-bottom: 20px;
}

.mi-subtitle {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px; /* Reduced from 30px */
}

.mi-wechat-text {
    color: #07C160; /* WeChat green */
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.mi-wechat-text:hover {
    color: #06A050;
    transform: translateY(-2px);
}

.mi-hero-qrcode {
    margin: 25px auto 0;
    width: 180px; /* Reduced from 250px */
    height: 180px; /* Reduced from 320px to make it square and smaller */
}

.mi-hero-qrcode img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.mi-content-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

.mi-content-section h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Reduced from 30px */
    color: #1a1a1a;
    font-size: 28px;
    position: relative;
    padding-bottom: 10px;
}

.mi-content-section h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: #07C160; /* WeChat green */
}

.mi-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.mi-interviewer-card {
    width: 320px;
    padding: 20px;
    margin: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.mi-interviewer-card:hover {
    transform: translateY(-5px);
}

.mi-interviewer-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin: 0 auto 15px;
    background-size: cover;
    background-position: center;
    overflow: hidden
}


.mi-interviewer-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.mi-interviewer-card h3 {
    color: #333;
    margin-bottom: 10px;
}

.mi-interviewer-card p {
    color: #666;
    margin-bottom: 8px;
}

.mi-info-card {
    flex: 1;
    min-width: 300px;
    margin: 15px;
    padding: 25px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.mi-info-card h2 {
    text-align: left;
    color: #333;
    margin-bottom: 20px;
    width: auto;
}

.mi-info-card h2:after {
    left: 0;
    transform: none;
}

.mi-offer-list, .mi-info-card ol {
    padding-left: 20px;
    margin-top: 15px;
}

.mi-offer-list li, .mi-info-card ol li {
    margin-bottom: 12px;
    color: #555;
    position: relative;
    padding-left: 10px;
}

.mi-offer-list li:before {
    content: '✓';
    color: #07C160; /* WeChat green */
    position: absolute;
    left: -15px;
}

.mi-contact-section {
    text-align: center;
    padding: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.mi-wechat-info {
    margin-top: 20px;
}

.mi-wechat-info p {
    margin-bottom: 15px;
    font-size: 18px;
    color: #555;
}

.mi-wechat-info strong {
    font-size: 20px;
}

/* Updated QR code styles */
.mi-qrcode-container {
    width: 300px; /* Reduced from 200px */
    height: 400px; /* Reduced from 200px */
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.mi-qrcode-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Hide the old placeholder */
.mi-qrcode-placeholder {
    display: none;
}

@media (max-width: 768px) {
    .mi-content-section {
        flex-direction: column;
    }
    
    .mi-info-card {
        margin: 10px 0;
    }
    
    .mi-interviewer-card {
        width: 100%;
        max-width: 350px;
        margin: 15px auto;
    }
    
    .mi-hero-section h1 {
        font-size: 28px;
    }
    
    /* Mobile responsive QR code */
    .mi-hero-qrcode, .mi-qrcode-container {
        width: 150px;
        height: 180px;
    }
}