.service-info-container {
    margin: 0 auto;
    padding: 70px 30px 30px;
    font-family: 'Arial, sans-serif';
    color: #333;
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-info-container h1 {
    color: #4b536a;
    font-size: 2.4em;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 15px;
}

.service-info-container h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #4b536a, #1d5876);
    border-radius: 2px;
}

.service-info-container > p {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.5;
    max-width: 600px;
}

.serviceList {
    list-style: none;
    padding: 0;
    margin: 0 auto 20px;
    width: 100%;
    max-width: 800px;
}

.serviceList li {
    position: relative;
    padding: 16px 16px 16px 60px;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    line-height: 1.5;
    font-size: 1.1em;
}

.serviceList li:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
}

.serviceList li::before {
    content: '✓';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    background-color: #4b536a;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.9em;
    font-weight: bold;
}

.pricing-section {
    background: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 550px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden;
}

.pricing-section::before {
    content: '限时优惠';
    position: absolute;
    top: 20px;
    right: -35px;
    background: #F44336;
    color: white;
    padding: 8px 40px;
    font-size: 0.85em;
    transform: rotate(45deg);
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.pricing-section h2 {
    color: #4b536a;
    font-size: 1.6em;
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.pricing-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 2px;
    background: #f0f0f0;
}

.original-price {
    font-size: 1.1em;
    color: #777;
    margin-bottom: 10px;
}

.original-price s {
    font-weight: normal;
}

.pricing-section p:nth-child(3) {
    font-size: 1.8em;
    color: #333;
    margin: 15px 0;
}

.pricing-section p:nth-child(3) strong {
    color: #F44336;
    font-size: 1.4em;
    font-weight: bold;
}

.discount-end {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
}

.countdown-timer {
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    padding: 15px;
    border-radius: 10px;
    font-size: 1.4em;
    font-weight: bold;
    color: #4b536a;
    margin-top: 20px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
    letter-spacing: 1px;
}

.purchase-button {
    background: linear-gradient(135deg, #4b536a, #364059);
    color: white;
    border: none;
    padding: 16px 40px;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(75, 83, 106, 0.3);
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
}

.purchase-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(75, 83, 106, 0.4);
    background: linear-gradient(135deg, #364059, #2a324a);
}

.purchase-button:active {
    transform: translateY(0);
}

.back-button {
    background: transparent;
    color: #4b536a;
    border: 2px solid #4b536a;
    padding: 12px 30px;
    font-size: 1em;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 30px;
}

.back-button:hover {
    background: rgba(75, 83, 106, 0.1);
    transform: translateY(-2px);
}

/* Pulse animation for the countdown */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.countdown-timer {
    animation: pulse 2s infinite;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .service-info-container {
        padding: 80px 20px 40px;
    }
    
    .service-info-container h1 {
        font-size: 2em;
    }
    
    .serviceList li {
        padding: 15px 15px 15px 50px;
        font-size: 1em;
    }
    
    .serviceList li::before {
        left: 15px;
        width: 22px;
        height: 22px;
    }
    
    .pricing-section {
        padding: 25px;
    }
    
    .pricing-section::before {
        font-size: 0.8em;
        padding: 6px 35px;
        right: -32px;
        top: 15px;
    }
    
    .countdown-timer {
        font-size: 1.2em;
    }
}