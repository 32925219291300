/* Base header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4b536a;
    padding: 10px 20px;
    color: white;
    position: fixed; /* Fixed header */
    top: 0;
    width: 100%; /* Full width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.3s ease; /* Smooth transition for any state changes */
}

/* Logo styling */
.logo {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 150px; /* Adjust size as needed */
    max-height: 50px; /* Prevent increasing header height */
    height: auto;
    transition: transform 0.3s ease; /* Added transition for hover effect */
}

.logo-img:hover {
    transform: scale(1.05); /* Subtle zoom effect on hover */
}

/* Navigation container styling */
.nav-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 20px; /* Gap between navigation items */
}

nav li {
    display: inline;
    position: relative; /* Added for hover underline effect */
}

nav a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1em;
    position: relative; /* Added for underline effect */
}

/* New underline animation effect */
nav a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #ffffff;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

nav a:hover::after {
    width: 70%; /* Underline width on hover */
}


/* User actions styling */
.user-actions {
    display: flex;
    align-items: center;
    gap: 15px; /* Gap between user name and logout button */
}

.user-actions a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
    position: relative; /* For potential hover effects */
}

.user-actions a:hover {
    color: #e0e0e0;
}

.logout-button, .login-button {
    background-color: #84888e; /* Distinct background color */
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 20px; /* Rounded button */
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9em; /* Adjusted size */
    text-decoration: none;
    margin-right: 25px;
    position: relative; /* For hover effects */
    overflow: hidden; /* For ripple effect */
}

.logout-button:hover, .login-button:hover {
    background-color: #3370df; /* Darker shade on hover */
    transform: scale(1.05) translateY(-2px); /* Combined zoom and slight up movement */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

/* Ripple effect for buttons */
.logout-button::after, .login-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%, -50%);
    transform-origin: 50% 50%;
}

.logout-button:focus::after, .login-button:focus::after {
    animation: ripple 1s ease-out;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }
    20% {
        transform: scale(25, 25);
        opacity: 0.3;
    }
    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}

/* Mobile menu toggle button */
.mobile-menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.mobile-menu-toggle:hover {
    transform: rotate(90deg);
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .header {
        flex-direction: row; /* Keep items in a row */
        padding: 5px 10px; /* Reduce padding */
        justify-content: space-between; /* Space out items */
        height: auto;
    }

    .mobile-menu-toggle {
        display: block;
        margin-left: auto;
        margin-right: 15px;
    }

    .logo {
        margin-right: auto; /* Push logo to the left */
    }

    .logo-img {
        width: 100px; /* Smaller logo size */
        max-height: 40px;
    }

    .nav-container {
        position: absolute;
        top: 60px; /* Below header */
        left: 0;
        width: 100%;
        background-color: #4b536a;
        flex-direction: column; /* Stack items when shown */
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
        align-items: center;
    }

    .header.nav-active .nav-container {
        max-height: 300px; /* Allow space for menu items */
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .nav-container a {
        font-size: 0.9em;
    }

    .nav-container ul {
        margin: 0;
        padding: 0px;
    }

    nav li {
        display: flex; /* Use flex for proper alignment */
        flex-direction: column; /* Stack text */
        align-items: center; /* Center items */
        width: 100%;
    }

    nav ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 15px; /* Consistent gap between items */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
        width: 100%;
    }

    nav a {
        width: 80%;
        text-align: center;
    }

    .user-actions {
        flex-direction: row; /* Align buttons in a row */
        align-items: center;
        justify-content: flex-end; /* Align to the right */
        margin-left: 10px; /* Small gap */
        gap: 10px;
    }

    .logout-button, .login-button {
        font-size: 0.8em;
        padding: 5px 6px; /* Adjust padding for buttons */
        margin-right: 5px;
    }
}